import { NavLink } from 'react-router';
import { NavItem } from '../types';
import { HomeIcon, UserIcon, BookOpenIcon, PhoneIcon } from '@heroicons/react/24/outline';

const navItems: NavItem[] = [
  { name: 'Blog', path: '/blog', icon: HomeIcon },
  // { name: 'About', path: '/about', icon: UserIcon },
  // { name: 'Services', path: '/services', icon: BookOpenIcon },
  // { name: 'Contact', path: '/contact', icon: PhoneIcon },
];

export const Navigation = () => {
  return (
    <>
      {/* Desktop Navigation */}
      <nav className="block bg-[#121212] shadow-md z-50  border-b border-b-[#272729]">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <NavLink to="/" className="flex-shrink-0 flex items-center space-x-4">
              <img src="/adaptive-small-icon.png" alt="Niki Coach" className="size-10 aspect-square" />
              <span className="text-2xl font-bold text-app-orange">Niki</span>
            </NavLink>
            <div className="flex space-x-8">
              {navItems.map((item) => (
                <NavLink
                  key={item.path}
                  to={item.path}
                  className={({ isActive }) =>
                    `text-app-orange hover:font-bold hover:opacity-100 px-3 py-2 rounded-md text-sm font-medium transition-colors
                    ${isActive ? 'opacity-100' : 'opacity-50'}`
                  }
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <nav className="hidden w-full bg-[#121212] shadow-lg z-50 border-t border-t-[#272729]">
        <div className="grid grid-cols-4 h-16">
          {navItems.map((item) => {
            const Icon = item.icon;
            return (
              <NavLink
                key={item.path}
                to={item.path}
                className={({ isActive }) =>
                  `flex flex-col items-center justify-center space-y-1 text-app-orange
                  ${isActive ? 'opacity-100' : 'opacity-50'}`
                }
              >
                <Icon className="h-6 w-6" />
                <span className="text-xs">{item.name}</span>
              </NavLink>
            );
          })}
        </div>
      </nav>
    </>
  );
};
